import React, { Fragment } from 'react'
import { graphql, PageProps } from 'gatsby'
import cn from 'classnames'
import { GetTixEventQuery, TixHarpaDates, TixHarpaDatesPricesPrices } from '@gql-types'
import { Button, Html, Icon, Link, Text } from '@cmp'
import { HyphenText } from '@connected'
import { getLanguageObj, stripHtmlString } from '@utils'
import { Menu, Footer, useLanguage, BaseLayout } from '@tmp'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { get, last, size, head } from 'lodash'
import { isSameDay, parseISO } from 'date-fns'
import { Element, scroller } from 'react-scroll'
import { EventHighlights } from '@src/query-components'
import { allLocales } from '@src/utils/locale'
import { withLanguageProvider } from '../context'
import { assertDefined, isDefined } from '@src/utils/helpers'
import { SOLD_OUT_STATUS } from '@src/utils/contants'

const PageTemplate = ({ data: { tixHarpa, allContentfulVenuePage } }: PageProps<GetTixEventQuery>) => {
  tixHarpa = assertDefined(tixHarpa)
  const { dateFormat, locale, t } = useLanguage()
  const tixObj = getLanguageObj(tixHarpa, locale)
  const promoter = tixHarpa?.dates?.[0]?.promoter
  const { eventPrices } = tixHarpa
  // @ts-expect-error
  const image = tixObj.featuredImage && getImage(tixObj.featuredImage)
  // @ts-expect-error
  const posterImage = tixObj.posterImage && getImage(tixObj.posterImage)
  const hallImage =
    // @ts-expect-error
    allContentfulVenuePage?.nodes?.[0]?.headerImage && getImage(allContentfulVenuePage?.nodes?.[0]?.headerImage)
  const categories = tixHarpa.categoriesMultiLang?.[locale]?.join(', ') ?? ''

  const onlineSaleStart = new Date(
    (tixObj.dates ?? [])
      .filter(isDefined)
      .sort(
        (a, b) => new Date(b.onlineSaleStart).getTime() - new Date(a.onlineSaleStart).getTime(),
      )[0].onlineSaleStart,
  )

  const onlineSaleHasNotStarted = onlineSaleStart > new Date()
  const saleNotScheduled = tixObj?.dates?.every(
    date => date?.saleStatusText === 'Sale not scheduled' || date?.saleStatusText === 'NotScheduled',
  )
  const allSoldOut = tixObj?.dates?.every(date => date?.saleStatus === SOLD_OUT_STATUS)

  const eventsOfTypeCount = size(tixObj.dates)
  const lastEventOfType = last(tixObj.dates)
  const firstEventOfType = head(tixObj.dates)

  const isSeries =
    eventsOfTypeCount > 1 &&
    !isSameDay(parseISO(firstEventOfType?.startDate), parseISO(lastEventOfType?.startDate))

  const isFreeEvent = tixObj?.dates?.[0]?.isFreeEvent ?? false

  // some events are free but require booking, should not be marked as free in the tix admin, but use the maxPrice to determine if it's free
  const freeRequiresBooking = eventPrices?.maxPrice === 0 && !isFreeEvent

  const showBookingButton = freeRequiresBooking || !isFreeEvent

  const renderImage = () => {
    if (posterImage) {
      return <GatsbyImage loading='eager' image={posterImage} alt='' className='w-full h-full' />
    } else if (image) {
      return <GatsbyImage loading='eager' image={image} alt='' className='w-full h-full' />
    } else {
      return null
    }
  }

  const getTicketText = () => {
    if (saleNotScheduled) {
      return t('saleNotScheduled')
    } else if (onlineSaleHasNotStarted && !freeRequiresBooking) {
      return `${t('ticketsale-starts')} ${dateFormat(onlineSaleStart, "do MMMM - 'kl.' p")}`
    } else if (allSoldOut) {
      return t('sold-out')
    } else if (freeRequiresBooking) {
      if (onlineSaleHasNotStarted) {
        return `${t('ticketbooking-start')} ${dateFormat(onlineSaleStart, "do MMMM - 'kl.' p")}`
      } else {
        return t('get-ticket')
      }
    } else {
      return t('buy-ticket')
    }
  }

  const getPurchaseUrl = () => {
    const url = tixObj?.purchaseUrls?.[locale === 'is' ? 0 : 1]?.link || undefined
    return url
  }

  // override default Tix sorting to make sure Almennt is always first
  const prices = get(tixObj, 'dates.[0].prices', []).sort((a, b) => {
    if (a?.ticketType === 'Almennt') return -1
    if (b?.ticketType === 'Almennt') return 1
    return 0
  })

  return (
    <BaseLayout
      defaultSeoOverwrites={{
        title: tixObj.name,
        ogTitle: tixObj.name,
        description: stripHtmlString(tixObj?.description ?? ''),
        ogType: 'event',
        ogImage: { file: { url: image?.images?.fallback?.src ?? '' } },
      }}
    >
      <div className='bg-black '>
        <main className='text-white pb-30'>
          <div className='relative'>
            <Menu
              localizedUrls={allLocales.map(locale => ({
                locale,
                route: { type: 'event', slug: tixObj.slug ?? '' },
              }))}
            />
            <div className='sticky top-0 z-10 flex flex-col w-full border-t lg:bg-black lg:bg-opacity-70 lg:backdrop-filter lg:backdrop-blur-sm lg:flex-row border-dark-200'>
              <div className='relative lg:w-1/2'>
                <Text variant='intro' weight='heavy' className='py-6 leading-none gutter'>
                  {categories ?? ''}
                </Text>
                <div className='absolute top-0 right-0 hidden w-px bg-dark-200 h-1/2 lg:block' />
              </div>
              <Text variant='intro' weight='heavy' className='hidden px-5 py-6 leading-none lg:block'>
                {t('footage')}
              </Text>
            </div>
            <div className='relative z-0 flex flex-col lg:-mt-20 lg:flex-row'>
              <div className='relative z-0 -mb-20  lg:ml-3.5 lg:pr-10 lg:mb-0 lg:w-1/2 lg:order-2 lg:pt-20'>
                <div className='absolute top-0 bottom-0 left-0 right-0 z-10 bg-gradient-to-t from-black lg:hidden'></div>
                <div className='max-h-full lg:sticky lg:top-20 lg:left-0'>{renderImage()}</div>
              </div>
              <div className='lg:w-1/2 lg:pt-36 gutter lg:mr-3.5 lg:order-1 relative'>
                {tixObj.name && (
                  <div className='mb-16'>
                    <HyphenText text={tixObj?.name ?? ''} variant='h2' as='h1' weight='heavy' />
                  </div>
                )}
                {showBookingButton && (
                  <Button
                    icon={!onlineSaleHasNotStarted && saleNotScheduled}
                    full
                    disabled={(onlineSaleHasNotStarted && saleNotScheduled) || allSoldOut}
                    className='text-dark-400 mb-14'
                    href={getPurchaseUrl()}
                  >
                    {getTicketText()}
                  </Button>
                )}
                <div className='flex flex-wrap justify-between mb-13 lg:mb-20'>
                  <Link
                    noStyle
                    className='mr-4 cursor-pointer xl:w-2/6 xl:mr-0 mb-7'
                    onClick={() => {
                      scroller.scrollTo('prices', { smooth: true, offset: -130 })
                    }}
                  >
                    <Text>{t('price')}</Text>

                    {saleNotScheduled ? (
                      <Text>-</Text>
                    ) : (
                      <Text weight='light' className='mb-2'>
                        {eventPrices?.minPrice?.toLocaleString('de-DE')}
                        {eventPrices?.minPrice !== eventPrices?.maxPrice &&
                          ` - ${eventPrices?.maxPrice?.toLocaleString('de-DE')}`}{' '}
                        kr
                      </Text>
                    )}
                    <Icon name='Arrow' className='w-3 h-3 transform rotate-45' />
                  </Link>
                  {Boolean(get(tixObj, 'dates.[0].startDate')) && (
                    <Link
                      noStyle
                      className='mr-4 cursor-pointer xl:w-2/6 xl:mr-0 mb-7'
                      onClick={() => {
                        scroller.scrollTo('whats-on', { smooth: true, offset: -100 })
                      }}
                    >
                      {isSeries ? (
                        <Fragment>
                          <Text>{t('period')}</Text>
                          <Text weight='light' className='mb-2'>
                            {`${dateFormat(new Date(get(tixObj, 'dates.[0].startDate')), 'do MMMM')} -
                        ${dateFormat(new Date(lastEventOfType?.startDate), 'do MMMM')}`}
                          </Text>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Text>{t('next-event')}</Text>
                          <Text weight='light' className='mb-2'>
                            {dateFormat(new Date(get(tixObj, 'dates.[0].startDate')), 'EEEE do MMMM - p')}
                          </Text>
                        </Fragment>
                      )}
                      <Icon name='Arrow' className='w-3 h-3 transform rotate-45' />
                    </Link>
                  )}
                  <Link
                    noStyle
                    className='mr-4 cursor-pointer xl:w-2/6 xl:mr-0 mb-7'
                    onClick={() => {
                      scroller.scrollTo('hall', { smooth: true })
                    }}
                  >
                    <Text>{t('hall')}</Text>
                    <Text weight='light' className='mb-2'>
                      {get(tixObj, 'dates.[0].hall', '')}
                    </Text>
                    <Icon name='Arrow' className='w-3 h-3 transform rotate-45' />
                  </Link>
                </div>
                <div className='mb-4'>{tixObj.description && <Html>{tixObj.description}</Html>}</div>
                <div className='mb-16'>
                  {promoter && (
                    <Fragment>
                      <Text variant='h4'>{t('promoter')}</Text>
                      <Text className='font-light'>{promoter}</Text>
                    </Fragment>
                  )}
                </div>
                <Element name='prices'>
                  <Text variant='intro' weight='heavy' className='mb-8'>
                    {t('ticket-prices-are')}
                  </Text>
                  <div className='flex flex-wrap mb-20'>
                    {prices?.[0]?.prices.map((item: TixHarpaDatesPricesPrices, idx: number) => (
                      <div className={cn('flex-grow px-6 py-4 border border-dark-200')} key={idx}>
                        <Text weight='bold'>{item.priceZone}</Text>
                        {saleNotScheduled ? (
                          <Text>-</Text>
                        ) : (
                          <Text>{item.price?.toLocaleString('de-DE')} kr.</Text>
                        )}
                      </div>
                    ))}
                  </div>
                </Element>
                <Element name='whats-on'>
                  <Text variant='intro' weight='heavy' className='mb-8'>
                    {t('whats-on')}
                  </Text>
                  {tixObj.dates?.map((date: TixHarpaDates | null, idx: number) => {
                    return (
                      <div key={idx} className='flex flex-col md:flex-row'>
                        <div
                          className={cn(
                            'flex items-center px-6 py-4 border-t border-l border-r md:w-4/6 md:border-b border-dark-200',
                            { 'md:w-full': isFreeEvent },
                          )}
                        >
                          <Text>
                            {date?.startDate && dateFormat(new Date(date?.startDate), "EEEE d'.' MMMM - p")}
                          </Text>
                        </div>
                        {showBookingButton && (
                          <div className='flex items-center px-6 py-4 border-b border-l border-r md:px-0 md:justify-center md:w-2/6 md:border-t border-dark-200'>
                            <Button
                              size='sm'
                              href={getPurchaseUrl() as string}
                              disabled={
                                (date?.soldOut as boolean) ||
                                onlineSaleHasNotStarted ||
                                date?.saleStatusText === 'Sale not scheduled' ||
                                date?.saleStatusText === 'NotScheduled'
                              }
                            >
                              {date?.soldOut ? t('sold-out') : getTicketText()}
                            </Button>
                          </div>
                        )}
                      </div>
                    )
                  })}
                </Element>
                {allContentfulVenuePage?.nodes?.length ? (
                  <Element name='hall' className='pt-20'>
                    <Text as='h3' variant='h3' weight='heavy' className='pb-8'>
                      {allContentfulVenuePage?.nodes?.[0]?.title ?? ''}
                    </Text>
                    <Text className='pb-2'>{allContentfulVenuePage?.nodes?.[0]?.intro?.intro ?? ''}</Text>
                    {hallImage && (
                      <GatsbyImage loading='eager' image={hallImage} alt='' className='w-full h-full' />
                    )}
                  </Element>
                ) : null}
              </div>
            </div>
          </div>
          <div className='mt-32'>
            <EventHighlights sliderContainerClass='pb-40 pt-16 md:pt-28' />
          </div>
        </main>
        <Footer />
      </div>
    </BaseLayout>
  )
}

export default withLanguageProvider(PageTemplate)

export const query = graphql`
  query GetTixEvent($id: String, $hall: String, $locale: String) {
    tixHarpa(id: { eq: $id }) {
      ...tixHarpaFragment
    }
    allContentfulVenuePage(filter: { title: { eq: $hall }, node_locale: { eq: $locale } }, limit: 1) {
      nodes {
        id
        title
        node_locale
        headerImage {
          gatsbyImageData(width: 700, quality: 100, placeholder: BLURRED)
        }
        intro {
          intro
        }
      }
    }
  }
`
